import React from 'react';
import './App.css';
import Timeline from './components/Timeline';
import Intro from './components/Into';
import Skills from './components/Skills'

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <Intro/>
          <Skills/>
          <Timeline/>
      </header>
    </div>
  );
}

export default App;
