import React from 'react';

import { FaTwitter, FaLinkedin, FaBloggerB } from 'react-icons/fa';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        marginLeft: theme.spacing(8),
        marginRight: theme.spacing(8)
    }
});

class Intro extends React.Component {

    render() {

        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <h1>Hi, I'm Eric.</h1>

                <a href="https://twitter.com/eric_j_ely" target="_blank" rel="noopener noreferrer">
                    <FaTwitter style={{margin: "4px"}} />
                </a>
                <a href="https://www.linkedin.com/in/eric-ely-b2448413/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin style={{margin: "4px"}} />
                </a>

                <a href="https://blog.eric-ely.com/" target="_blank" rel="noopener noreferrer">
                    <FaBloggerB style={{margin: "4px"}} />
                </a>

                <p>
                    I'm a repeat entrepreneur and software professional with a passion for building products and companies.
                    I have built technology products from the ground up, built and managed teams of engineers, worked
                    cross functionally to achieve business goals, and helped define business strategy and vision.
                </p>
                <p>You can see many of my projects on my GitHub page <a
                    href="https://github.com/eely22" target="_blank" rel="noopener noreferrer">
                        @eely22
                    </a>
                </p>

            </div>
        )
    }

}

export default withStyles(styles)(Intro);;
