import React from 'react';
import Chip from '@material-ui/core/Chip';

class Skills extends React.Component {

    render() {

        let proficientSkills = ['Python', 'React', 'AWS', 'MySQL', 'Elasticsearch', 'DynamoDB', ];
        let moderateSkills = ['MongoDB', 'C#', 'C/C++', 'Swift', 'Java'];

        return (
            <div>
                <p>Technology Skills</p>
                {proficientSkills.map((skill) => (
                    <Chip
                        style={{margin: "2px", backgroundColor: "#228c22", color: "#fff"}}
                        key={skill}
                        label={skill}
                    />
                ))}
                {moderateSkills.map((skill) => (
                    <Chip
                        style={{margin: "2px", backgroundColor: "#b2d3c2", color: "#fff"}}
                        key={skill}
                        label={skill}
                    />
                ))}
            </div>
        )
    }

}

export default Skills;
