import React from 'react';

import './Timeline.css';

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import { IoMdSchool } from 'react-icons/io';
import { FaMoneyCheckAlt } from 'react-icons/fa';
import { MdWork } from 'react-icons/md';

class Timeline extends React.Component {

    render() {

        return (
            <div>
                <p>For all the highlights, scroll down through my timeline.</p>
                <VerticalTimeline>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="February 2021 - Present"
                        iconStyle={{ background: 'rgb(13, 130, 223)', color: '#fff' }}
                        icon={<MdWork />}
                    >
                        <h3 className="verticalTimelineElementTitle">Kincern</h3>
                        <h4 className="verticalTimelineElementSubtitle">Manchester, NH</h4>
                        <p className="verticalTimelineElementText">
                            Co-Founder/CTO
                            <ul>
                                <li>Built SaaS platform to coordinate administrative and financial care of elders</li>
                                <li>Executed C-level functions of an early-stage startup</li>
                                <li>Aligned product roadmap and technology choices with company strategy</li>
                                <li>Wrote code, designed wireframes, managed team, and chose third party data/service
                                    vendors</li>
                            </ul>
                        </p>
                        <a href="https://kincern.com/" target="_blank" rel="noopener noreferrer"><img alt="" height="64" src="/kincern_logo.jpeg"/></a>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="May 2020 - February 2021"
                        iconStyle={{ background: 'rgb(13, 130, 223)', color: '#fff' }}
                        icon={<MdWork />}
                    >
                        <h3 className="verticalTimelineElementTitle">Cohere Health</h3>
                        <h4 className="verticalTimelineElementSubtitle">Boston, MA</h4>
                        <p className="verticalTimelineElementText">
                            Senior Software Engineer
                            <ul>
                                <li>Worked on the next generation of prior authorizations for the insurance industry</li>
                                <li>Led team cross functionally with clinical inputs to build a sophisticated rules engine to
                                    align medical and financial care decisions</li>
                                <li>Worked with AWS, MongoDB, Groovy/Grails, Typescript/React</li>
                            </ul>
                        </p>
                        <a href="https://coherehealth.com/" target="_blank" rel="noopener noreferrer"><img alt="" height="64" src="/cohere_logo.png"/></a>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="December 2019 - May 2020"
                        iconStyle={{ background: 'rgb(13, 130, 223)', color: '#fff' }}
                        icon={<MdWork />}
                    >
                        <h3 className="verticalTimelineElementTitle">Zywave</h3>
                        <h4 className="verticalTimelineElementSubtitle">Bedford, NH</h4>
                        <p className="verticalTimelineElementText">
                            Senior Software Architect
                            <ul>
                                <li>Automated manual data collection and cleansing prcesses in Zywave</li>
                                <li>Managed team of local and remote engineers</li>
                                <li>Brought miEdge technologies and products into the Zywave portfolio</li>
                            </ul>
                        </p>
                        <a href="https://www.zywave.com/" target="_blank" rel="noopener noreferrer"><img alt="" height="64" src="/zywave_logo.png"/></a>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="December 2019"
                        iconStyle={{ background: 'rgb(13, 130, 223)', color: '#fff' }}
                        icon={<FaMoneyCheckAlt />}
                    >
                        <h3 className="verticalTimelineElementTitle">miEdge Acquisition</h3>

                        <p className="verticalTimelineElementText">
                            miEdge acquired by Zywave
                            <br/>
                            <a
                                href="https://www.zywave.com/news/zywave-acquires-miedge-becomes-leading-data-provider-in-the-industry/"
                                target="_blank" rel="noopener noreferrer"
                            ><img alt="" height="48" src="/miwave_logo.png"/></a>
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="November 2018 - Present"
                        iconStyle={{ background: 'rgb(13, 130, 223)', color: '#fff' }}
                        icon={<MdWork />}
                    >
                        <h3 className="verticalTimelineElementTitle">Clipcast</h3>
                        <h4 className="verticalTimelineElementSubtitle">Goffstown, NH</h4>
                        <p className="verticalTimelineElementText">
                            Co-Founder
                            <ul>
                                <li>Built a search engine for podcasts</li>
                                <li>Automatically adds new episodes to search index when they are published to the RSS feed</li>
                                <li>Create embedded player which can be added to customers websites, allowing podcast search
                                    on any web property
                                </li>
                            </ul>
                        </p>
                        <a href="https://clipcast.app/" target="_blank" rel="noopener noreferrer"><img alt="" height="48" src="/clipcast_logo.png"/></a>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="May 2016 - December 2019"
                        iconStyle={{ background: 'rgb(13, 130, 223)', color: '#fff' }}
                        icon={<MdWork />}
                    >
                        <h3 className="verticalTimelineElementTitle">miEdge</h3>
                        <h4 className="verticalTimelineElementSubtitle">Bedford, NH</h4>
                        <p className="verticalTimelineElementText">
                            Principal Software Engineer
                            <ul>
                                <li>Built entire technology stack from the ground up using Python, AWS and React</li>
                                <li>Architected and implemented sophisticated data processing streams</li>
                                <li>Experience with storage technologies such as MySQL, MariaDB, DynamoDB, Elasticsearch,
                                    redis, and s3</li>
                                <li>Utilized AWS services to build and deploy products quickly</li>
                            </ul>
                        </p>
                        <a href="https://miedge.biz/" target="_blank" rel="noopener noreferrer"><img alt="" height="64" src="/miedge_logo.png"/></a>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="May 2015 - May 2016"
                        iconStyle={{ background: 'rgb(13, 130, 223)', color: '#fff' }}
                        icon={<MdWork />}
                    >
                        <h3 className="verticalTimelineElementTitle">MidAir Tech</h3>
                        <h4 className="verticalTimelineElementSubtitle">Goffstown, NH</h4>
                        <p className="verticalTimelineElementText">
                            Co-Founder
                            <ul>
                                <li>Created startup with one other founder for bluz and GoGlove</li>
                                <li>Sourced manufacturers and design houses for specific components of the product</li>
                                <li>Developed all portions of products, including PCB design, embedded code, apps, back-end
                                    tools to collect data and documentation</li>
                                <li>Defined all product requirements for bluz, and ran all sales and marketing efforts</li>
                            </ul>
                        </p>
                        <a href="https://bluz.io/" target="_blank" rel="noopener noreferrer"><img alt="" height="64" src="/bluz_logo.png"/></a>
                        <a href="http://goglove.io/" target="_blank" rel="noopener noreferrer"><img alt="" className="ggLogo" height="40" src="/gg_logo.png"/></a>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="April 2015"
                        iconStyle={{ background: 'rgb(13, 130, 223)', color: '#fff' }}
                        icon={<FaMoneyCheckAlt />}
                    >
                        <h3 className="verticalTimelineElementTitle">Bluz Kickstarter</h3>

                        <p className="verticalTimelineElementText">
                            Raised over $67k for bluz, a BLE development kit for the Particle platform.
                            <br/>
                            <a
                                href="https://www.kickstarter.com/projects/264813863/bluz-bluetooth-made-smarter"
                                target="_blank" rel="noopener noreferrer"
                            ><img alt="" height="20" src="/ks_logo.png"/></a>
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="November 2014"
                        iconStyle={{ background: 'rgb(13, 130, 223)', color: '#fff' }}
                        icon={<FaMoneyCheckAlt />}
                    >
                        <h3 className="verticalTimelineElementTitle">GoGlove Kickstarter</h3>

                        <p className="verticalTimelineElementText">
                            Raised over $45k for GoGlove, a wearable wireless remote.
                            <br/>
                            <a
                                href="https://www.kickstarter.com/projects/264813863/goglove-life-has-a-soundtrack-control-yours"
                                target="_blank" rel="noopener noreferrer"
                            ><img alt="" height="20" src="/ks_logo.png"/></a>
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="July 2012 - May 2015"
                        iconStyle={{ background: 'rgb(13, 130, 223)', color: '#fff' }}
                        icon={<MdWork />}
                    >
                        <h3 className="verticalTimelineElementTitle">Azimuth Systems</h3>
                        <h4 className="verticalTimelineElementSubtitle">Acton, MA</h4>
                        <p className="verticalTimelineElementText">
                            Principal Solutions Engineer
                            <ul>
                                <li>Wrote portions of a large scale web application with AngularJS, HTML/CSS, and Breeze</li>
                                <li>Managed development team to create custom solutions and automation platforms</li>
                                <li>Managed multiple products simultaneously while architecting, developing and testing,
                                    spending approximately 75% of my time coding in C#, Java, Python, and Javascript</li>
                                <li>Architected web applications from the ground up, including back-end and front-end</li>
                            </ul>
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="June 2011 - July 2012"
                        iconStyle={{ background: 'rgb(13, 130, 223)', color: '#fff' }}
                        icon={<MdWork />}
                    >
                        <h3 className="verticalTimelineElementTitle">Azimuth Systems</h3>
                        <h4 className="verticalTimelineElementSubtitle">Acton, MA</h4>
                        <p className="verticalTimelineElementText">
                            Product Manager Hybrid
                            <ul>
                                <li>Managed various product lines by gathering customer requirements, building a business
                                    case, driving roadmaps and managing major accounts through the sales process</li>
                                <li>Traveled to customers around the world to gather feedback on the OTA product line</li>
                                <li>Presented material to customers in a pre-sales role to drive new opportunities</li>
                                <li>Attended and spoke at various trade shows and conferences</li>
                            </ul>
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="April 2006 - June 2011"
                        iconStyle={{ background: 'rgb(13, 130, 223)', color: '#fff' }}
                        icon={<MdWork />}
                    >
                        <h3 className="verticalTimelineElementTitle">Azimuth Systems</h3>
                        <h4 className="verticalTimelineElementSubtitle">Acton, MA</h4>
                        <p className="verticalTimelineElementText">
                            Software Engineer
                            <ul>
                                <li>Experience with GUI design and development with C#, .NET, Windows Forms, and
                                    WPF using Visual Studio and Expression Blend</li>
                                <li>Hands on problem solving for a variety of wireless technologies</li>
                                <li>Worked across RF and HW disciplines to implement a reverberation chamber based OTA
                                    test platform for mobile devices</li>
                                <li>Worked directly with customers on installations, demos, and product enhancements</li>
                            </ul>
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="May 2016"
                        iconStyle={{ background: 'rgb(13, 130, 223)', color: '#fff' }}
                        icon={<IoMdSchool />}
                    >
                        <h3 className="verticalTimelineElementTitle">Graduation</h3>
                        <h4 className="verticalTimelineElementSubtitle">Durham, NH</h4>
                        <p className="verticalTimelineElementText">
                            Graduated University of New Hampshire with BS in Computer Science
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="December 2004 - April 2006"
                        iconStyle={{ background: 'rgb(13, 130, 223)', color: '#fff' }}
                        icon={<MdWork />}
                    >
                        <h3 className="verticalTimelineElementTitle">InterOperability Lab</h3>
                        <h4 className="verticalTimelineElementSubtitle">Durham, NH</h4>
                        <p className="verticalTimelineElementText">
                            Wireless Consortium Technician
                            <ul>
                                <li>Automated UNH-IOL MAC Test Suite on Azimuth 800W series wireless test platform</li>
                                <li>Wrote customized automation software for UNH-IOL IEEE 802.11 MAC Test Suite</li>
                            </ul>
                            <a href="https://www.iol.unh.edu/" target="_blank" rel="noopener noreferrer">
                                <img alt="" height="48" src="/iol_logo.png"/>
                            </a>
                        </p>
                    </VerticalTimelineElement>
                </VerticalTimeline>
            </div>
        )
    }

}

export default Timeline;
